<template>
	<div class="CommodityFinish">
		<div class="header">
			<div class="header-img">
				<img src="@/assets/img/icon1003.png" alt="">
			</div>
			<div class="header-title">
				支付成功
			</div>
		</div>
		<div class="body">
			<div class="body-item">
				<div class="item-title">下单时间：</div>
				<div class="item-text">{{detail.create_time}}</div>
			</div>
			<div class="body-item">
				<div class="item-title">订单编号：</div>
				<div class="item-text">{{detail.order_num}}</div>
			</div>
			<div class="body-item">
				<div class="item-title">支付方式：</div>
				<div class="item-text">{{detail.total_pay_amount}}元+{{detail.total_integral}}爱心积分</div>
			</div>
			<template v-if="detail.pay_code == 0">
				<div class="body-item">
					<div class="item-title">收货姓名：</div>
					<div class="item-text">{{detail.real_name}}</div>
				</div>
				<div class="body-item">
					<div class="item-title">收货地址：</div>
					<div class="item-text">{{detail.full_address}}</div>
				</div>
			</template>
		</div>
		<div class="footer">
			<div class="footer-text" @click="toOrderList">查看订单</div>
		</div>
	</div>
</template>

<script>
	import Vant from '@/vendor/vant.js';
	import Commodity from '@/api/commodity';
	export default{
		name: 'CommodityFinish',
		data() {
			return{
				detail: ""
			}
		},
		created() {
			this.detail = this.$route.params.data
		},
		methods:{
			toOrderList(){
				this.$router.replace({
					name: 'ConsumeList'
				});
			}
		}
	}
</script>

<style scoped lang="less">
	.CommodityFinish{
		background-color: #FFF;
		.header{
			.header-img{
				margin: 0 auto;
				width: 132px;
				height: 132px;
				img{
					width: 100%;
					height: 100%;
					display: block;
					align-items: center;
				}
			}
			.header-title{
				margin-top: 15px;
				font-size: 18px;
				line-height: 26px;
				text-align: center;
				color: #000;
			}
		}
		.body{
			margin: 0 10px;
			padding: 0 10px;
			box-sizing: border-box;
			.body-item:first-child{
				margin-top: 35px;
			}
			.body-item{
				display: flex;
				justify-content: space-between;
				margin-top: 16px;
				.item-title{
					font-size: 16px;
					line-height: 22px;
					color: #777;
				}
				.item-text{
					width: 230px;
					min-width: 230px;
					font-size: 16px;
					line-height: 22px;
					color: #151C39;
				}
			}
		}
		.footer{
			margin: 0 10px;
			background: #3377FF;
			border-radius: 8px;
			margin-top: 35px;
			padding: 10px 0;
			.footer-text{
				font-size: 18px;
				line-height: 26px;
				text-align: center;
				color: #FFF;
			}
		}
	}
</style>
